const YandexMapGeneral = {
  getCurrentGeo() {
    return new Promise((resolve, reject) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const currentCoords = position.coords;
            const { latitude, longitude } = currentCoords;

            resolve({ latitude, longitude });
          },
          (error) => {
            reject('Geolocation detection error: ', error);
          },
        );
      } else {
        reject('Geolocation is not available');
      }
    });
  },

  centerMapWithCurrentGeo() {
    ymaps.ready(async () => {
      try {
        const currentPosition = await this.getCurrentGeo();

        await this.map.setCenter(
          [currentPosition.latitude, currentPosition.longitude],
          16,
        );

        return Promise.resolve();
      } catch (error) {
        throw error;
      }
    });
  },
  
  async putGeoInfoToHidddenInputs(coords) {
    const $modalBody = $(this.modalBody);
    const $form = $modalBody.find('#hidden-data');

    const $inputID = $form.find('input[name="ID"]');
    const $inputZIP = $form.find('input[name="UF_ZIP"]');
    const $inputCountry = $form.find('input[name="UF_COUNTRY"]');
    const $inputCity = $form.find('input[name="UF_CITY"]');
    const $inputStreet = $form.find('input[name="UF_STREET"]');
    const $inputHouse = $form.find('input[name="UF_HOUSE"]');
    const $inputLat = $form.find('input[name="UF_LAT"]');
    const $inputLng = $form.find('input[name="UF_LNG"]');

    const geoInfo = await this.getPlacemarkGeoInfo(coords);

    $inputID.val(this.addressId);
    $inputZIP.val(geoInfo.postalCode);
    $inputCountry.val(geoInfo.country);
    $inputCity.val(geoInfo.locality);
    $inputStreet.val(geoInfo.thoroughfare);
    $inputHouse.val(geoInfo.premiseNumber);
    $inputLat.val(geoInfo.latitude);
    $inputLng.val(geoInfo.longitude);
  },
};

window.YandexMapGeneral = YandexMapGeneral;
